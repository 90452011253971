/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddressDTO,
    AddressDTOFromJSON,
    AddressDTOFromJSONTyped,
    AddressDTOToJSON,
} from './AddressDTO';
import {
    InputDTO,
    InputDTOFromJSON,
    InputDTOFromJSONTyped,
    InputDTOToJSON,
} from './InputDTO';

/**
 * 
 * @export
 * @interface ClientDTO
 */
export interface ClientDTO {
    /**
     * 
     * @type {number}
     * @memberof ClientDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ClientDTO
     */
    managerId?: number;
    /**
     * 
     * @type {string}
     * @memberof ClientDTO
     */
    identifier?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientDTO
     */
    email?: string;
    /**
     * 
     * @type {AddressDTO}
     * @memberof ClientDTO
     */
    address?: AddressDTO;
    /**
     * 
     * @type {string}
     * @memberof ClientDTO
     */
    additionalInfo?: string;
    /**
     * 
     * @type {Array<InputDTO>}
     * @memberof ClientDTO
     */
    inputs?: Array<InputDTO>;
    /**
     * 
     * @type {boolean}
     * @memberof ClientDTO
     */
    billed?: boolean;
}

export function ClientDTOFromJSON(json: any): ClientDTO {
    return ClientDTOFromJSONTyped(json, false);
}

export function ClientDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'managerId': !exists(json, 'managerId') ? undefined : json['managerId'],
        'identifier': !exists(json, 'identifier') ? undefined : json['identifier'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'address': !exists(json, 'address') ? undefined : AddressDTOFromJSON(json['address']),
        'additionalInfo': !exists(json, 'additionalInfo') ? undefined : json['additionalInfo'],
        'inputs': !exists(json, 'inputs') ? undefined : ((json['inputs'] as Array<any>).map(InputDTOFromJSON)),
        'billed': !exists(json, 'billed') ? undefined : json['billed'],
    };
}

export function ClientDTOToJSON(value?: ClientDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'managerId': value.managerId,
        'identifier': value.identifier,
        'email': value.email,
        'address': AddressDTOToJSON(value.address),
        'additionalInfo': value.additionalInfo,
        'inputs': value.inputs === undefined ? undefined : ((value.inputs as Array<any>).map(InputDTOToJSON)),
        'billed': value.billed,
    };
}

