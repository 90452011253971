/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClientBillingRequest
 */
export interface ClientBillingRequest {
    /**
     * 
     * @type {number}
     * @memberof ClientBillingRequest
     */
    managerId?: number;
    /**
     * 
     * @type {string}
     * @memberof ClientBillingRequest
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientBillingRequest
     */
    city?: string;
    /**
     * 
     * @type {number}
     * @memberof ClientBillingRequest
     */
    serviceId?: number;
    /**
     * 
     * @type {number}
     * @memberof ClientBillingRequest
     */
    providerId?: number;
}

export function ClientBillingRequestFromJSON(json: any): ClientBillingRequest {
    return ClientBillingRequestFromJSONTyped(json, false);
}

export function ClientBillingRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientBillingRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'managerId': !exists(json, 'managerId') ? undefined : json['managerId'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'serviceId': !exists(json, 'serviceId') ? undefined : json['serviceId'],
        'providerId': !exists(json, 'providerId') ? undefined : json['providerId'],
    };
}

export function ClientBillingRequestToJSON(value?: ClientBillingRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'managerId': value.managerId,
        'state': value.state,
        'city': value.city,
        'serviceId': value.serviceId,
        'providerId': value.providerId,
    };
}

