/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RateDTO,
    RateDTOFromJSON,
    RateDTOFromJSONTyped,
    RateDTOToJSON,
} from './RateDTO';

/**
 * 
 * @export
 * @interface InputDTO
 */
export interface InputDTO {
    /**
     * 
     * @type {number}
     * @memberof InputDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof InputDTO
     */
    meterId?: number;
    /**
     * 
     * @type {string}
     * @memberof InputDTO
     */
    meterKey?: string;
    /**
     * 
     * @type {string}
     * @memberof InputDTO
     */
    meterSerialNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof InputDTO
     */
    inputTypeId?: number;
    /**
     * 
     * @type {number}
     * @memberof InputDTO
     */
    clientId?: number;
    /**
     * 
     * @type {number}
     * @memberof InputDTO
     */
    serviceProvidedId?: number;
    /**
     * 
     * @type {number}
     * @memberof InputDTO
     */
    providerId?: number;
    /**
     * 
     * @type {RateDTO}
     * @memberof InputDTO
     */
    rate?: RateDTO;
    /**
     * 
     * @type {boolean}
     * @memberof InputDTO
     */
    hasError?: boolean;
}

export function InputDTOFromJSON(json: any): InputDTO {
    return InputDTOFromJSONTyped(json, false);
}

export function InputDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): InputDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'meterId': !exists(json, 'meterId') ? undefined : json['meterId'],
        'meterKey': !exists(json, 'meterKey') ? undefined : json['meterKey'],
        'meterSerialNumber': !exists(json, 'meterSerialNumber') ? undefined : json['meterSerialNumber'],
        'inputTypeId': !exists(json, 'inputTypeId') ? undefined : json['inputTypeId'],
        'clientId': !exists(json, 'clientId') ? undefined : json['clientId'],
        'serviceProvidedId': !exists(json, 'serviceProvidedId') ? undefined : json['serviceProvidedId'],
        'providerId': !exists(json, 'providerId') ? undefined : json['providerId'],
        'rate': !exists(json, 'rate') ? undefined : RateDTOFromJSON(json['rate']),
        'hasError': !exists(json, 'hasError') ? undefined : json['hasError'],
    };
}

export function InputDTOToJSON(value?: InputDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'meterId': value.meterId,
        'meterKey': value.meterKey,
        'meterSerialNumber': value.meterSerialNumber,
        'inputTypeId': value.inputTypeId,
        'clientId': value.clientId,
        'serviceProvidedId': value.serviceProvidedId,
        'providerId': value.providerId,
        'rate': RateDTOToJSON(value.rate),
        'hasError': value.hasError,
    };
}

