/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ManagerDTO,
    ManagerDTOFromJSON,
    ManagerDTOToJSON,
} from '../models';

export interface CreateRequest {
    managerDTO: ManagerDTO;
}

export interface GetRequest {
    id: number;
}

export interface RemoveRequest {
    id: number;
}

export interface SendWelcomeMailRequest {
    id: number;
}

export interface UpdateRequest {
    managerDTO: ManagerDTO;
}

/**
 * 
 */
export class ManagerControllerApi extends runtime.BaseAPI {

    /**
     */
    async createRaw(requestParameters: CreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ManagerDTO>> {
        if (requestParameters.managerDTO === null || requestParameters.managerDTO === undefined) {
            throw new runtime.RequiredError('managerDTO','Required parameter requestParameters.managerDTO was null or undefined when calling create.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/managers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ManagerDTOToJSON(requestParameters.managerDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManagerDTOFromJSON(jsonValue));
    }

    /**
     */
    async create(requestParameters: CreateRequest, initOverrides?: RequestInit): Promise<ManagerDTO> {
        const response = await this.createRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getRaw(requestParameters: GetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ManagerDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling get.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/managers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManagerDTOFromJSON(jsonValue));
    }

    /**
     */
    async get(requestParameters: GetRequest, initOverrides?: RequestInit): Promise<ManagerDTO> {
        const response = await this.getRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ManagerDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/managers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ManagerDTOFromJSON));
    }

    /**
     */
    async list(initOverrides?: RequestInit): Promise<Array<ManagerDTO>> {
        const response = await this.listRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async removeRaw(requestParameters: RemoveRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling remove.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/managers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async remove(requestParameters: RemoveRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.removeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async sendWelcomeMailRaw(requestParameters: SendWelcomeMailRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ManagerDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sendWelcomeMail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/managers/mail/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManagerDTOFromJSON(jsonValue));
    }

    /**
     */
    async sendWelcomeMail(requestParameters: SendWelcomeMailRequest, initOverrides?: RequestInit): Promise<ManagerDTO> {
        const response = await this.sendWelcomeMailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateRaw(requestParameters: UpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ManagerDTO>> {
        if (requestParameters.managerDTO === null || requestParameters.managerDTO === undefined) {
            throw new runtime.RequiredError('managerDTO','Required parameter requestParameters.managerDTO was null or undefined when calling update.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/managers`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ManagerDTOToJSON(requestParameters.managerDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManagerDTOFromJSON(jsonValue));
    }

    /**
     */
    async update(requestParameters: UpdateRequest, initOverrides?: RequestInit): Promise<ManagerDTO> {
        const response = await this.updateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
