import { useRoutes } from 'react-router-dom';

// routes
import config from 'config';
import AuthenticationRoutes from './AuthenticationRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function PrivateRoutes() {
    return useRoutes([AuthenticationRoutes], config.basename);
}
