/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MeterLogDTO,
    MeterLogDTOFromJSON,
    MeterLogDTOToJSON,
} from '../models';

export interface ListAlarmsRequest {
    id: number;
}

/**
 * 
 */
export class MeterLogControllerApi extends runtime.BaseAPI {

    /**
     */
    async listAlarmsRaw(requestParameters: ListAlarmsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<MeterLogDTO>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling listAlarms.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/alarms/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MeterLogDTOFromJSON));
    }

    /**
     */
    async listAlarms(requestParameters: ListAlarmsRequest, initOverrides?: RequestInit): Promise<Array<MeterLogDTO>> {
        const response = await this.listAlarmsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
