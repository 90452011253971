/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InputDTO,
    InputDTOFromJSON,
    InputDTOFromJSONTyped,
    InputDTOToJSON,
} from './InputDTO';

/**
 * 
 * @export
 * @interface MeterDTO
 */
export interface MeterDTO {
    /**
     * 
     * @type {number}
     * @memberof MeterDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof MeterDTO
     */
    accountId?: number;
    /**
     * 
     * @type {string}
     * @memberof MeterDTO
     */
    accountIdentifier?: string;
    /**
     * 
     * @type {number}
     * @memberof MeterDTO
     */
    managerId?: number;
    /**
     * 
     * @type {string}
     * @memberof MeterDTO
     */
    managerFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof MeterDTO
     */
    managerLastName?: string;
    /**
     * 
     * @type {string}
     * @memberof MeterDTO
     */
    serialNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof MeterDTO
     */
    key?: string;
    /**
     * 
     * @type {Array<InputDTO>}
     * @memberof MeterDTO
     */
    inputs?: Array<InputDTO>;
    /**
     * 
     * @type {boolean}
     * @memberof MeterDTO
     */
    onError?: boolean;
}

export function MeterDTOFromJSON(json: any): MeterDTO {
    return MeterDTOFromJSONTyped(json, false);
}

export function MeterDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeterDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'accountId': !exists(json, 'accountId') ? undefined : json['accountId'],
        'accountIdentifier': !exists(json, 'accountIdentifier') ? undefined : json['accountIdentifier'],
        'managerId': !exists(json, 'managerId') ? undefined : json['managerId'],
        'managerFirstName': !exists(json, 'managerFirstName') ? undefined : json['managerFirstName'],
        'managerLastName': !exists(json, 'managerLastName') ? undefined : json['managerLastName'],
        'serialNumber': !exists(json, 'serialNumber') ? undefined : json['serialNumber'],
        'key': !exists(json, 'key') ? undefined : json['key'],
        'inputs': !exists(json, 'inputs') ? undefined : ((json['inputs'] as Array<any>).map(InputDTOFromJSON)),
        'onError': !exists(json, 'onError') ? undefined : json['onError'],
    };
}

export function MeterDTOToJSON(value?: MeterDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'accountId': value.accountId,
        'accountIdentifier': value.accountIdentifier,
        'managerId': value.managerId,
        'managerFirstName': value.managerFirstName,
        'managerLastName': value.managerLastName,
        'serialNumber': value.serialNumber,
        'key': value.key,
        'inputs': value.inputs === undefined ? undefined : ((value.inputs as Array<any>).map(InputDTOToJSON)),
        'onError': value.onError,
    };
}

