import { appStore, EMPTY_USER } from '../store/appStore';
import jwt_decode from 'jwt-decode';
import _ from 'lodash';
import { RoleTypeDTO } from '../libs/src/lib/sdk';

export const getUserRole = () => {
    if (_.isEqual(EMPTY_USER, appStore.user)) {
        if (localStorage.getItem('outboxtek.logintoken')) {
            // @ts-ignore
            const token = JSON.parse(localStorage.getItem('outboxtek.logintoken'));
            if (!_.isEmpty(token)) {
                appStore.user = jwt_decode(token.jwt);
            }
        }
    }

    if (appStore.user?.authorities?.includes(RoleTypeDTO.Admin)) {
        return RoleTypeDTO.Admin;
    }

    if (appStore.user?.authorities?.includes(RoleTypeDTO.Manager)) {
        return RoleTypeDTO.Manager;
    }

    return 'ROLE_GUEST';
};
