import Button from '../Button/Button';
import React, { CSSProperties } from 'react';
import spinnerIcon from '../../assets/images/icons/spinner.gif';
import styles from './SpinnerIconButton.module.scss';

const SpinnerIconButton = ({
    text,
    className,
    onClick,
    disabled,
    icon,
    spinning,
    style,
    textSpinning
}: {
    text: string;
    className: string;
    onClick: () => void;
    disabled: boolean;
    icon: JSX.Element;
    spinning: boolean;
    style?: CSSProperties;
    textSpinning: string;
}) => {
    return (
        <Button
            style={style}
            text={spinning ? textSpinning : text}
            className={className}
            onClick={onClick}
            disabled={spinning ? true : disabled}
            icon={!spinning ? icon : <img className={styles.spinner} src={spinnerIcon} alt={'loading'} />}
        />
    );
};

export default SpinnerIconButton;
