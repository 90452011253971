import React, { useEffect, useRef, useState } from 'react';
import Button from '../../../../ui-component/Button/Button';
import { IconCheck, IconCircleX } from '@tabler/icons';
import Modal from '../../../../ui-component/Modal/Modal';
import SpinnerIconButton from '../../../../ui-component/SpinnerIconButton/SpinnerIconButton';
import { TextField } from '@mui/material';
import Row from '../../../../ui-component/Row/Row';
import styles from './ChangeSignature.module.scss';
import { isDevMode, signatureControllerApi } from '../../../../libs/src/lib';
import { appStore } from '../../../../store/appStore';

interface Signature {
    logo: any;
    text: string;
}

const ChangeSignature = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
    const [spinning, setSpinning] = useState(false);
    const [signature, setSignature] = useState<Signature>({ logo: '', text: '' });

    const imageRef = useRef<any>();

    const handleSave = async () => {
        setSpinning(true);

        if (signature) {
            const formData = new FormData();
            const headers = new Headers();

            formData.append('text', signature.text);
            formData.append('managerId', '' + appStore.user.id);
            formData.append('file', signature.logo);

            const accessToken = localStorage.getItem('outboxtek.logintoken');
            if (accessToken) {
                headers.append('Authorization', 'Bearer ' + JSON.parse(accessToken).jwt);
            }

            fetch(isDevMode() ? 'http://localhost:8080/api/signature' : '/api/signature', {
                method: 'POST',
                headers: headers,
                body: formData
            })
                .then((xx) => {
                    setSpinning(false);
                    handleClose();
                })
                .catch((error) => {
                    console.log('error while saving signature');
                    setSpinning(false);
                });
        }
    };

    const getSignature = async () => {
        const signatureDTO = await signatureControllerApi.getManagerSignature({ managerId: appStore.user.id });
        setSignature({ logo: 'data:image;base64,' + signatureDTO.file, text: signatureDTO.text || '' });
    };

    useEffect(() => {
        if (open) {
            getSignature();
        }
    }, [open]);

    const shouldDisable = () => {
        return !signature.text || !signature.logo;
    };

    const buttons = [
        <Button text={'Discard'} className={'buttonSecondary'} icon={<IconCircleX />} onClick={() => handleClose()} />,
        <SpinnerIconButton
            text={'Save'}
            textSpinning={'Saving ...'}
            className={'buttonSuccess'}
            onClick={() => handleSave()}
            disabled={shouldDisable() || spinning}
            icon={<IconCheck />}
            spinning={spinning}
        />
    ];

    const handleClose = () => {
        setSignature({ logo: '', text: '' });
        onClose();
    };

    return (
        <Modal
            width={'sm'}
            id={'change-signature-modal'}
            title={'My Signature'}
            closable={true}
            open={open}
            onClose={() => handleClose()}
            buttons={buttons}
        >
            <label className={styles.label}>Upload photo or logo</label>
            <Row style={{ width: '100%', marginTop: -24 }}>
                <TextField
                    type={'file'}
                    inputProps={{ accept: 'image/*' }}
                    variant={'outlined'}
                    fullWidth={true}
                    onChange={(event: any) => {
                        const file = event.target.files[0];
                        setSignature({ ...signature, logo: file });
                        const reader = new FileReader();
                        reader.onload = (event) => {
                            if (event.target) imageRef.current.src = event.target.result;
                        };
                        reader.readAsDataURL(file);
                    }}
                />
            </Row>

            {signature.logo && (
                <Row style={{ width: '100%', justifyContent: 'center' }}>
                    <img ref={imageRef} src={signature.logo} alt={'signature'} width={250} />
                </Row>
            )}
            <Row style={{ width: '100%' }}>
                <TextField
                    label={'Text signature'}
                    multiline
                    rows={2}
                    variant={'outlined'}
                    fullWidth={true}
                    value={signature.text || ''}
                    onChange={(event) => {
                        setSignature({ ...signature, text: event.target.value });
                    }}
                />
            </Row>

            <Row style={{ width: '100%' }}>
                <small>This signature will be added automatically to all bills.</small>
            </Row>
        </Modal>
    );
};

export default ChangeSignature;
