/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClientDTO,
    ClientDTOFromJSON,
    ClientDTOFromJSONTyped,
    ClientDTOToJSON,
} from './ClientDTO';
import {
    Tier,
    TierFromJSON,
    TierFromJSONTyped,
    TierToJSON,
} from './Tier';

/**
 * 
 * @export
 * @interface BillDetailsDTO
 */
export interface BillDetailsDTO {
    /**
     * 
     * @type {number}
     * @memberof BillDetailsDTO
     */
    managerId?: number;
    /**
     * 
     * @type {ClientDTO}
     * @memberof BillDetailsDTO
     */
    client?: ClientDTO;
    /**
     * 
     * @type {string}
     * @memberof BillDetailsDTO
     */
    consumption?: string;
    /**
     * 
     * @type {Array<Tier>}
     * @memberof BillDetailsDTO
     */
    tiers?: Array<Tier>;
    /**
     * 
     * @type {string}
     * @memberof BillDetailsDTO
     */
    clientTotal?: string;
    /**
     * 
     * @type {string}
     * @memberof BillDetailsDTO
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof BillDetailsDTO
     */
    endDate?: string;
    /**
     * 
     * @type {string}
     * @memberof BillDetailsDTO
     */
    fireLine?: string;
    /**
     * 
     * @type {string}
     * @memberof BillDetailsDTO
     */
    others?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BillDetailsDTO
     */
    recipients?: Array<string>;
}

export function BillDetailsDTOFromJSON(json: any): BillDetailsDTO {
    return BillDetailsDTOFromJSONTyped(json, false);
}

export function BillDetailsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillDetailsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'managerId': !exists(json, 'managerId') ? undefined : json['managerId'],
        'client': !exists(json, 'client') ? undefined : ClientDTOFromJSON(json['client']),
        'consumption': !exists(json, 'consumption') ? undefined : json['consumption'],
        'tiers': !exists(json, 'tiers') ? undefined : ((json['tiers'] as Array<any>).map(TierFromJSON)),
        'clientTotal': !exists(json, 'clientTotal') ? undefined : json['clientTotal'],
        'startDate': !exists(json, 'startDate') ? undefined : json['startDate'],
        'endDate': !exists(json, 'endDate') ? undefined : json['endDate'],
        'fireLine': !exists(json, 'fireLine') ? undefined : json['fireLine'],
        'others': !exists(json, 'others') ? undefined : json['others'],
        'recipients': !exists(json, 'recipients') ? undefined : json['recipients'],
    };
}

export function BillDetailsDTOToJSON(value?: BillDetailsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'managerId': value.managerId,
        'client': ClientDTOToJSON(value.client),
        'consumption': value.consumption,
        'tiers': value.tiers === undefined ? undefined : ((value.tiers as Array<any>).map(TierToJSON)),
        'clientTotal': value.clientTotal,
        'startDate': value.startDate,
        'endDate': value.endDate,
        'fireLine': value.fireLine,
        'others': value.others,
        'recipients': value.recipients,
    };
}

