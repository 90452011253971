import React from 'react';

// material-ui
import { styled } from '@mui/material/styles';
import { Card, CardContent } from '@mui/material';

const CardStyle = styled(Card)(({ theme }) => ({
    background: theme.palette.primary.light,
    marginBottom: '22px',
    overflow: 'hidden',
    position: 'relative'
}));

// ==============================|| PROGRESS BAR WITH LABEL ||============================== //

const MenuCard = ({ children }) => {
    return (
        <CardStyle style={{ marginBottom: '8px' }}>
            <CardContent sx={{ p: 2 }}>{children}</CardContent>
        </CardStyle>
    );
};

export default MenuCard;
