/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BracketDTO,
    BracketDTOFromJSON,
    BracketDTOFromJSONTyped,
    BracketDTOToJSON,
} from './BracketDTO';
import {
    ProviderDTO,
    ProviderDTOFromJSON,
    ProviderDTOFromJSONTyped,
    ProviderDTOToJSON,
} from './ProviderDTO';
import {
    RateType,
    RateTypeFromJSON,
    RateTypeFromJSONTyped,
    RateTypeToJSON,
} from './RateType';
import {
    ServiceProvidedDTO,
    ServiceProvidedDTOFromJSON,
    ServiceProvidedDTOFromJSONTyped,
    ServiceProvidedDTOToJSON,
} from './ServiceProvidedDTO';

/**
 * 
 * @export
 * @interface RateDTO
 */
export interface RateDTO {
    /**
     * 
     * @type {number}
     * @memberof RateDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof RateDTO
     */
    identifier?: string;
    /**
     * 
     * @type {string}
     * @memberof RateDTO
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof RateDTO
     */
    state?: string;
    /**
     * 
     * @type {ProviderDTO}
     * @memberof RateDTO
     */
    provider?: ProviderDTO;
    /**
     * 
     * @type {ServiceProvidedDTO}
     * @memberof RateDTO
     */
    service?: ServiceProvidedDTO;
    /**
     * 
     * @type {Array<BracketDTO>}
     * @memberof RateDTO
     */
    brackets?: Array<BracketDTO>;
    /**
     * 
     * @type {RateType}
     * @memberof RateDTO
     */
    type?: RateType;
    /**
     * 
     * @type {Date}
     * @memberof RateDTO
     */
    updated?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof RateDTO
     */
    used?: boolean;
}

export function RateDTOFromJSON(json: any): RateDTO {
    return RateDTOFromJSONTyped(json, false);
}

export function RateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): RateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'identifier': !exists(json, 'identifier') ? undefined : json['identifier'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'provider': !exists(json, 'provider') ? undefined : ProviderDTOFromJSON(json['provider']),
        'service': !exists(json, 'service') ? undefined : ServiceProvidedDTOFromJSON(json['service']),
        'brackets': !exists(json, 'brackets') ? undefined : ((json['brackets'] as Array<any>).map(BracketDTOFromJSON)),
        'type': !exists(json, 'type') ? undefined : RateTypeFromJSON(json['type']),
        'updated': !exists(json, 'updated') ? undefined : (new Date(json['updated'])),
        'used': !exists(json, 'used') ? undefined : json['used'],
    };
}

export function RateDTOToJSON(value?: RateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'identifier': value.identifier,
        'city': value.city,
        'state': value.state,
        'provider': ProviderDTOToJSON(value.provider),
        'service': ServiceProvidedDTOToJSON(value.service),
        'brackets': value.brackets === undefined ? undefined : ((value.brackets as Array<any>).map(BracketDTOToJSON)),
        'type': RateTypeToJSON(value.type),
        'updated': value.updated === undefined ? undefined : (value.updated.toISOString()),
        'used': value.used,
    };
}

