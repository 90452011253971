import { useRoutes } from 'react-router-dom';

// routes
import config from 'config';
import ManagerRoutesDescription from './ManagerRoutesDescription';

// ==============================|| ROUTING RENDER ||============================== //

export default function ManagerRoutes() {
    return useRoutes([ManagerRoutesDescription], config.basename);
}
