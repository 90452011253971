// material-ui
import React from 'react';
import logo from 'assets/images/logo.png';

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    return <img src={logo} alt="Berry" width="160px" />;
};

export default Logo;
