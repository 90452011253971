/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RateInfoRequestDTO
 */
export interface RateInfoRequestDTO {
    /**
     * 
     * @type {number}
     * @memberof RateInfoRequestDTO
     */
    serviceId?: number;
    /**
     * 
     * @type {number}
     * @memberof RateInfoRequestDTO
     */
    clientId?: number;
    /**
     * 
     * @type {number}
     * @memberof RateInfoRequestDTO
     */
    providerId?: number;
}

export function RateInfoRequestDTOFromJSON(json: any): RateInfoRequestDTO {
    return RateInfoRequestDTOFromJSONTyped(json, false);
}

export function RateInfoRequestDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): RateInfoRequestDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'serviceId': !exists(json, 'serviceId') ? undefined : json['serviceId'],
        'clientId': !exists(json, 'clientId') ? undefined : json['clientId'],
        'providerId': !exists(json, 'providerId') ? undefined : json['providerId'],
    };
}

export function RateInfoRequestDTOToJSON(value?: RateInfoRequestDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'serviceId': value.serviceId,
        'clientId': value.clientId,
        'providerId': value.providerId,
    };
}

