/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ProviderDTO,
    ProviderDTOFromJSON,
    ProviderDTOToJSON,
} from '../models';

export interface CreateProviderRequest {
    providerDTO: ProviderDTO;
}

export interface GetProviderRequest {
    id: number;
}

export interface ListProvidersByServiceAndClientRequest {
    serviceId: number;
    clientId: number;
}

export interface ListProvidersByStateAndCityAndServiceRequest {
    state: string;
    city: string;
    serviceId: number;
}

export interface RemoveProviderRequest {
    id: number;
}

export interface UpdateProviderRequest {
    providerDTO: ProviderDTO;
}

/**
 * 
 */
export class ProviderControllerApi extends runtime.BaseAPI {

    /**
     */
    async createProviderRaw(requestParameters: CreateProviderRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProviderDTO>> {
        if (requestParameters.providerDTO === null || requestParameters.providerDTO === undefined) {
            throw new runtime.RequiredError('providerDTO','Required parameter requestParameters.providerDTO was null or undefined when calling createProvider.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/providers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProviderDTOToJSON(requestParameters.providerDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProviderDTOFromJSON(jsonValue));
    }

    /**
     */
    async createProvider(requestParameters: CreateProviderRequest, initOverrides?: RequestInit): Promise<ProviderDTO> {
        const response = await this.createProviderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getProviderRaw(requestParameters: GetProviderRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProviderDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getProvider.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/providers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProviderDTOFromJSON(jsonValue));
    }

    /**
     */
    async getProvider(requestParameters: GetProviderRequest, initOverrides?: RequestInit): Promise<ProviderDTO> {
        const response = await this.getProviderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listProvidersRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ProviderDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/providers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProviderDTOFromJSON));
    }

    /**
     */
    async listProviders(initOverrides?: RequestInit): Promise<Array<ProviderDTO>> {
        const response = await this.listProvidersRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async listProvidersByServiceAndClientRaw(requestParameters: ListProvidersByServiceAndClientRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ProviderDTO>>> {
        if (requestParameters.serviceId === null || requestParameters.serviceId === undefined) {
            throw new runtime.RequiredError('serviceId','Required parameter requestParameters.serviceId was null or undefined when calling listProvidersByServiceAndClient.');
        }

        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling listProvidersByServiceAndClient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/providers/service/{serviceId}/client/{clientId}`.replace(`{${"serviceId"}}`, encodeURIComponent(String(requestParameters.serviceId))).replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProviderDTOFromJSON));
    }

    /**
     */
    async listProvidersByServiceAndClient(requestParameters: ListProvidersByServiceAndClientRequest, initOverrides?: RequestInit): Promise<Array<ProviderDTO>> {
        const response = await this.listProvidersByServiceAndClientRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listProvidersByStateAndCityAndServiceRaw(requestParameters: ListProvidersByStateAndCityAndServiceRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ProviderDTO>>> {
        if (requestParameters.state === null || requestParameters.state === undefined) {
            throw new runtime.RequiredError('state','Required parameter requestParameters.state was null or undefined when calling listProvidersByStateAndCityAndService.');
        }

        if (requestParameters.city === null || requestParameters.city === undefined) {
            throw new runtime.RequiredError('city','Required parameter requestParameters.city was null or undefined when calling listProvidersByStateAndCityAndService.');
        }

        if (requestParameters.serviceId === null || requestParameters.serviceId === undefined) {
            throw new runtime.RequiredError('serviceId','Required parameter requestParameters.serviceId was null or undefined when calling listProvidersByStateAndCityAndService.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/providers/state/{state}/city/{city}/service/{serviceId}`.replace(`{${"state"}}`, encodeURIComponent(String(requestParameters.state))).replace(`{${"city"}}`, encodeURIComponent(String(requestParameters.city))).replace(`{${"serviceId"}}`, encodeURIComponent(String(requestParameters.serviceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProviderDTOFromJSON));
    }

    /**
     */
    async listProvidersByStateAndCityAndService(requestParameters: ListProvidersByStateAndCityAndServiceRequest, initOverrides?: RequestInit): Promise<Array<ProviderDTO>> {
        const response = await this.listProvidersByStateAndCityAndServiceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async removeProviderRaw(requestParameters: RemoveProviderRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling removeProvider.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/providers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async removeProvider(requestParameters: RemoveProviderRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.removeProviderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateProviderRaw(requestParameters: UpdateProviderRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProviderDTO>> {
        if (requestParameters.providerDTO === null || requestParameters.providerDTO === undefined) {
            throw new runtime.RequiredError('providerDTO','Required parameter requestParameters.providerDTO was null or undefined when calling updateProvider.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/providers`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProviderDTOToJSON(requestParameters.providerDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProviderDTOFromJSON(jsonValue));
    }

    /**
     */
    async updateProvider(requestParameters: UpdateProviderRequest, initOverrides?: RequestInit): Promise<ProviderDTO> {
        const response = await this.updateProviderRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
