import React, { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const AccountsList = Loadable(lazy(() => import('views/accounts/index')));
const MetersList = Loadable(lazy(() => import('views/meters/index')));
const ManagersList = Loadable(lazy(() => import('views/managers/index')));
const RatesList = Loadable(lazy(() => import('views/rates/index')));
const ProvidersList = Loadable(lazy(() => import('views/providers/index')));

const AdminRoutesDescription = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/managers',
            element: <ManagersList />
        },
        {
            path: '/accounts',
            element: <AccountsList />
        },
        {
            path: '/providers',
            element: <ProvidersList />
        },
        {
            path: '/rates',
            element: <RatesList />
        },
        {
            path: '/meters',
            element: <MetersList />
        }
    ]
};

export default AdminRoutesDescription;
