/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    NewKeywordRequestDTO,
    NewKeywordRequestDTOFromJSON,
    NewKeywordRequestDTOToJSON,
    NewUnitRequestDTO,
    NewUnitRequestDTOFromJSON,
    NewUnitRequestDTOToJSON,
    ServiceProvidedDTO,
    ServiceProvidedDTOFromJSON,
    ServiceProvidedDTOToJSON,
} from '../models';

export interface SaveKeywordRequest {
    newKeywordRequestDTO: NewKeywordRequestDTO;
}

export interface SaveUnitRequest {
    newUnitRequestDTO: NewUnitRequestDTO;
}

/**
 * 
 */
export class ServiceProvidedControllerApi extends runtime.BaseAPI {

    /**
     */
    async listServicesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ServiceProvidedDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/services`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ServiceProvidedDTOFromJSON));
    }

    /**
     */
    async listServices(initOverrides?: RequestInit): Promise<Array<ServiceProvidedDTO>> {
        const response = await this.listServicesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async saveKeywordRaw(requestParameters: SaveKeywordRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ServiceProvidedDTO>> {
        if (requestParameters.newKeywordRequestDTO === null || requestParameters.newKeywordRequestDTO === undefined) {
            throw new runtime.RequiredError('newKeywordRequestDTO','Required parameter requestParameters.newKeywordRequestDTO was null or undefined when calling saveKeyword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/services`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewKeywordRequestDTOToJSON(requestParameters.newKeywordRequestDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceProvidedDTOFromJSON(jsonValue));
    }

    /**
     */
    async saveKeyword(requestParameters: SaveKeywordRequest, initOverrides?: RequestInit): Promise<ServiceProvidedDTO> {
        const response = await this.saveKeywordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async saveUnitRaw(requestParameters: SaveUnitRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ServiceProvidedDTO>> {
        if (requestParameters.newUnitRequestDTO === null || requestParameters.newUnitRequestDTO === undefined) {
            throw new runtime.RequiredError('newUnitRequestDTO','Required parameter requestParameters.newUnitRequestDTO was null or undefined when calling saveUnit.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/services/unit`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewUnitRequestDTOToJSON(requestParameters.newUnitRequestDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceProvidedDTOFromJSON(jsonValue));
    }

    /**
     */
    async saveUnit(requestParameters: SaveUnitRequest, initOverrides?: RequestInit): Promise<ServiceProvidedDTO> {
        const response = await this.saveUnitRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
