import React, { CSSProperties } from 'react';
import styles from './Row.module.scss';

const Row = ({ style, className, onClick, children }: { children: any, onClick?:()=> void, style?: CSSProperties; className?: string }) => {
    return (
        <div className={`${styles.row} ${className ?? className}`} style={style} onClick={onClick ?? onClick}>
            {children}
        </div>
    );
};

export default Row;
