import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { RoleTypeDTO } from './libs/src/lib/sdk';
import { observer } from 'mobx-react-lite';
import { applyRoutes } from './auth/authRoutes';
import { getUserRole } from './auth/authService';

// ==============================|| APP ||============================== //

const App = observer(() => {
    const customization = useSelector((state) => state.customization);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const pathName = location.pathname;

        if (getUserRole()) {
            if (getUserRole() === RoleTypeDTO.Admin && (pathName.includes('login') || pathName.includes('register')))
                navigate('/managers', { replace: true });

            if (getUserRole() === RoleTypeDTO.Manager && (pathName.includes('login') || pathName.includes('register')))
                navigate('/dashboard', { replace: true });

            if (getUserRole() === 'ROLE_GUEST') {
                navigate('pages/login', { replace: true });
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>{applyRoutes()}</NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
});

export default App;
