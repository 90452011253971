/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ClientBillingRequest,
    ClientBillingRequestFromJSON,
    ClientBillingRequestToJSON,
    ClientDTO,
    ClientDTOFromJSON,
    ClientDTOToJSON,
} from '../models';

export interface CreateClientRequest {
    clientDTO: ClientDTO;
}

export interface FindAllByManagerAndStateAndCityAndServiceAndProviderRequest {
    clientBillingRequest: ClientBillingRequest;
}

export interface GetClientRequest {
    id: number;
}

export interface GetClientsByAccountAndServiceRequest {
    accountId: number;
    serviceId: number;
}

export interface GetClientsByManagerRequest {
    id: number;
}

export interface RemoveClientRequest {
    id: number;
}

export interface UpdateClientRequest {
    clientDTO: ClientDTO;
}

/**
 * 
 */
export class ClientControllerApi extends runtime.BaseAPI {

    /**
     */
    async createClientRaw(requestParameters: CreateClientRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ClientDTO>> {
        if (requestParameters.clientDTO === null || requestParameters.clientDTO === undefined) {
            throw new runtime.RequiredError('clientDTO','Required parameter requestParameters.clientDTO was null or undefined when calling createClient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/clients`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClientDTOToJSON(requestParameters.clientDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientDTOFromJSON(jsonValue));
    }

    /**
     */
    async createClient(requestParameters: CreateClientRequest, initOverrides?: RequestInit): Promise<ClientDTO> {
        const response = await this.createClientRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findAllByManagerAndStateAndCityAndServiceAndProviderRaw(requestParameters: FindAllByManagerAndStateAndCityAndServiceAndProviderRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ClientDTO>>> {
        if (requestParameters.clientBillingRequest === null || requestParameters.clientBillingRequest === undefined) {
            throw new runtime.RequiredError('clientBillingRequest','Required parameter requestParameters.clientBillingRequest was null or undefined when calling findAllByManagerAndStateAndCityAndServiceAndProvider.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/clients/billing`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClientBillingRequestToJSON(requestParameters.clientBillingRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClientDTOFromJSON));
    }

    /**
     */
    async findAllByManagerAndStateAndCityAndServiceAndProvider(requestParameters: FindAllByManagerAndStateAndCityAndServiceAndProviderRequest, initOverrides?: RequestInit): Promise<Array<ClientDTO>> {
        const response = await this.findAllByManagerAndStateAndCityAndServiceAndProviderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getClientRaw(requestParameters: GetClientRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ClientDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getClient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/clients/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientDTOFromJSON(jsonValue));
    }

    /**
     */
    async getClient(requestParameters: GetClientRequest, initOverrides?: RequestInit): Promise<ClientDTO> {
        const response = await this.getClientRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getClientsByAccountAndServiceRaw(requestParameters: GetClientsByAccountAndServiceRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ClientDTO>>> {
        if (requestParameters.accountId === null || requestParameters.accountId === undefined) {
            throw new runtime.RequiredError('accountId','Required parameter requestParameters.accountId was null or undefined when calling getClientsByAccountAndService.');
        }

        if (requestParameters.serviceId === null || requestParameters.serviceId === undefined) {
            throw new runtime.RequiredError('serviceId','Required parameter requestParameters.serviceId was null or undefined when calling getClientsByAccountAndService.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/clients/account/{accountId}/service/{serviceId}`.replace(`{${"accountId"}}`, encodeURIComponent(String(requestParameters.accountId))).replace(`{${"serviceId"}}`, encodeURIComponent(String(requestParameters.serviceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClientDTOFromJSON));
    }

    /**
     */
    async getClientsByAccountAndService(requestParameters: GetClientsByAccountAndServiceRequest, initOverrides?: RequestInit): Promise<Array<ClientDTO>> {
        const response = await this.getClientsByAccountAndServiceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getClientsByManagerRaw(requestParameters: GetClientsByManagerRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ClientDTO>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getClientsByManager.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/clients/manager/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClientDTOFromJSON));
    }

    /**
     */
    async getClientsByManager(requestParameters: GetClientsByManagerRequest, initOverrides?: RequestInit): Promise<Array<ClientDTO>> {
        const response = await this.getClientsByManagerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listClientsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ClientDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/clients`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClientDTOFromJSON));
    }

    /**
     */
    async listClients(initOverrides?: RequestInit): Promise<Array<ClientDTO>> {
        const response = await this.listClientsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async removeClientRaw(requestParameters: RemoveClientRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling removeClient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/clients/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async removeClient(requestParameters: RemoveClientRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.removeClientRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateClientRaw(requestParameters: UpdateClientRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ClientDTO>> {
        if (requestParameters.clientDTO === null || requestParameters.clientDTO === undefined) {
            throw new runtime.RequiredError('clientDTO','Required parameter requestParameters.clientDTO was null or undefined when calling updateClient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/clients`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ClientDTOToJSON(requestParameters.clientDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientDTOFromJSON(jsonValue));
    }

    /**
     */
    async updateClient(requestParameters: UpdateClientRequest, initOverrides?: RequestInit): Promise<ClientDTO> {
        const response = await this.updateClientRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
