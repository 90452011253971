/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddressDTO
 */
export interface AddressDTO {
    /**
     * 
     * @type {number}
     * @memberof AddressDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    additionalInfo?: string;
}

export function AddressDTOFromJSON(json: any): AddressDTO {
    return AddressDTOFromJSONTyped(json, false);
}

export function AddressDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddressDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'street': !exists(json, 'street') ? undefined : json['street'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'additionalInfo': !exists(json, 'additionalInfo') ? undefined : json['additionalInfo'],
    };
}

export function AddressDTOToJSON(value?: AddressDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'street': value.street,
        'city': value.city,
        'state': value.state,
        'additionalInfo': value.additionalInfo,
    };
}

