import React, { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const ManagerDashboard = Loadable(lazy(() => import('views/dashboard/manager/ManagerDashboard')));
const AccountsList = Loadable(lazy(() => import('views/accounts/index')));
const ClientShowPage = Loadable(lazy(() => import('views/clients/ClientShowPage/ClientShowPage')));
const BillingPage = Loadable(lazy(() => import('views/billing/index')));
const ClientsList = Loadable(lazy(() => import('views/clients/index')));
const MetersList = Loadable(lazy(() => import('views/meters/index')));

const ManagerRoutesDescription = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/dashboard',
            element: <ManagerDashboard />
        },
        {
            path: '/accounts',
            element: <AccountsList />
        },
        {
            path: '/client/show/:id',
            element: <ClientShowPage />
        },
        {
            path: '/billing',
            element: <BillingPage />
        },
        {
            path: '/clients',
            element: <ClientsList />
        },
        {
            path: '/meters',
            element: <MetersList />
        }
    ]
};

export default ManagerRoutesDescription;
