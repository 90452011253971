import React from 'react';
import { Breakpoint, Dialog, DialogActions, DialogContent } from '@mui/material';
import styles from './Modal.module.scss';
import { IconX } from '@tabler/icons';

const Modal = ({
    id,
    open,
    title,
    width,
    children,
    buttons,
    onClose,
    closable = true
}: {
    id: string;
    open: boolean;
    title: any;
    width: Breakpoint;
    children: any;
    buttons?: any[];
    onClose?: () => void;
    closable?: boolean;
}) => {
    const scroll = 'paper';

    const handleOnClose = () => {
        onClose && onClose();
    };

    return (
        <Dialog open={open} scroll={'paper'} maxWidth={width} fullWidth={true}>
            <div className={styles.title}>
                <div>{title}</div>
                {closable && (
                    <div onClick={handleOnClose} className={styles.iconClose}>
                        <IconX />
                    </div>
                )}
            </div>
            <DialogContent dividers={scroll === 'paper'} style={{ paddingBottom: 32 }}>
                {children}
            </DialogContent>
            {buttons && (
                <DialogActions>
                    {buttons.map((button, index) => (
                        <div key={`${id}button${index}`}>{button}</div>
                    ))}
                </DialogActions>
            )}
        </Dialog>
    );
};

export default Modal;
