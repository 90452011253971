import React from 'react';
import AdminRoutes from '../routes/AdminRoutes';
import { getUserRole } from './authService';
import ManagerRoutes from '../routes/ManagerRoutes';
import PrivateRoutes from '../routes/PrivateRoutes';

export const applyRoutes = () => {
    switch (getUserRole()) {
        case 'ROLE_ADMIN':
            return <AdminRoutes />;
        case 'ROLE_MANAGER':
            return <ManagerRoutes />;
        default:
            return <PrivateRoutes />;
    }
};
