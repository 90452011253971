/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthenticationRequestDTO,
    AuthenticationRequestDTOFromJSON,
    AuthenticationRequestDTOToJSON,
    AuthenticationResponseDTO,
    AuthenticationResponseDTOFromJSON,
    AuthenticationResponseDTOToJSON,
} from '../models';

export interface CreateAuthenticationTokenRequest {
    authenticationRequestDTO: AuthenticationRequestDTO;
}

/**
 * 
 */
export class AuthenticationControllerApi extends runtime.BaseAPI {

    /**
     */
    async createAuthenticationTokenRaw(requestParameters: CreateAuthenticationTokenRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AuthenticationResponseDTO>> {
        if (requestParameters.authenticationRequestDTO === null || requestParameters.authenticationRequestDTO === undefined) {
            throw new runtime.RequiredError('authenticationRequestDTO','Required parameter requestParameters.authenticationRequestDTO was null or undefined when calling createAuthenticationToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/authenticate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthenticationRequestDTOToJSON(requestParameters.authenticationRequestDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthenticationResponseDTOFromJSON(jsonValue));
    }

    /**
     */
    async createAuthenticationToken(requestParameters: CreateAuthenticationTokenRequest, initOverrides?: RequestInit): Promise<AuthenticationResponseDTO> {
        const response = await this.createAuthenticationTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
