/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum RoleTypeDTO {
    Admin = 'ROLE_ADMIN',
    Manager = 'ROLE_MANAGER',
    Guest = 'ROLE_GUEST'
}

export function RoleTypeDTOFromJSON(json: any): RoleTypeDTO {
    return RoleTypeDTOFromJSONTyped(json, false);
}

export function RoleTypeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleTypeDTO {
    return json as RoleTypeDTO;
}

export function RoleTypeDTOToJSON(value?: RoleTypeDTO | null): any {
    return value as any;
}

