import React, { CSSProperties } from 'react';
import { Button as ButtonMui } from '@mui/material';
import styles from './Button.module.scss';

const Button = ({
    text,
    className,
    icon,
    onClick,
    disabled,
    style
}: {
    text?: string;
    className: string;
    icon?: any;
    onClick: () => void;
    disabled?: boolean;
    style?: CSSProperties;
}) => {
    return (
        <div style={style}>
            <ButtonMui
                className={className}
                onClick={onClick}
                disabled={disabled}
                style={!text ? { maxWidth: 34, maxHeight: 34, minWidth: 34, minHeight: 34 } : {}}
            >
                {icon && <div className={styles.iconContainer}>{icon}</div>}
                {text && <div style={{ paddingRight: 4, marginLeft: 8 }}>{text}</div>}
            </ButtonMui>
        </div>
    );
};

export default Button;
