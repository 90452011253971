/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MeterLogDTO
 */
export interface MeterLogDTO {
    /**
     * 
     * @type {number}
     * @memberof MeterLogDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof MeterLogDTO
     */
    meterId?: number;
    /**
     * 
     * @type {string}
     * @memberof MeterLogDTO
     */
    pulseCnt1?: string;
    /**
     * 
     * @type {string}
     * @memberof MeterLogDTO
     */
    pulseCnt2?: string;
    /**
     * 
     * @type {string}
     * @memberof MeterLogDTO
     */
    pulseCnt3?: string;
    /**
     * 
     * @type {string}
     * @memberof MeterLogDTO
     */
    rmsVoltsLn1?: string;
    /**
     * 
     * @type {number}
     * @memberof MeterLogDTO
     */
    timeStampUtcMs?: number;
}

export function MeterLogDTOFromJSON(json: any): MeterLogDTO {
    return MeterLogDTOFromJSONTyped(json, false);
}

export function MeterLogDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeterLogDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'meterId': !exists(json, 'meterId') ? undefined : json['meterId'],
        'pulseCnt1': !exists(json, 'pulseCnt1') ? undefined : json['pulseCnt1'],
        'pulseCnt2': !exists(json, 'pulseCnt2') ? undefined : json['pulseCnt2'],
        'pulseCnt3': !exists(json, 'pulseCnt3') ? undefined : json['pulseCnt3'],
        'rmsVoltsLn1': !exists(json, 'rmsVoltsLn1') ? undefined : json['rmsVoltsLn1'],
        'timeStampUtcMs': !exists(json, 'timeStampUtcMs') ? undefined : json['timeStampUtcMs'],
    };
}

export function MeterLogDTOToJSON(value?: MeterLogDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'meterId': value.meterId,
        'pulseCnt1': value.pulseCnt1,
        'pulseCnt2': value.pulseCnt2,
        'pulseCnt3': value.pulseCnt3,
        'rmsVoltsLn1': value.rmsVoltsLn1,
        'timeStampUtcMs': value.timeStampUtcMs,
    };
}

