import { observable } from 'mobx';

export interface UserDetails {
    id: number;
    sub: string;
    iat: number;
    exp: number;
    fullName: string;
    authorities: string[];
    jti: string;
}

export const EMPTY_USER = {
    id: 0,
    sub: '',
    iat: 0,
    exp: 0,
    fullName: '',
    authorities: [],
    jti: ''
};

export type AppState = {
    user: UserDetails;
};

export const appStore: AppState = observable({
    user: EMPTY_USER
});
