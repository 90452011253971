import React, { useState } from 'react';
import Modal from '../../../../ui-component/Modal/Modal';
import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from '../../../../ui-component/Button/Button';
import { IconCheck, IconCircleX } from '@tabler/icons';
import SpinnerIconButton from '../../../../ui-component/SpinnerIconButton/SpinnerIconButton';
import _ from 'lodash';
import { userControllerApi } from '../../../../libs/src/lib';

const ChangePassword = ({ open, onClose, onSuccess }: { open: boolean; onClose: () => void; onSuccess: () => void }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [spinning, setSpinning] = useState(false);
    const [credentialsError, setCredentialsError] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event: any) => {
        event.preventDefault();
    };

    const handleClickShowPasswordConfirmation = () => {
        setShowPasswordConfirmation(!showPasswordConfirmation);
    };

    const handleMouseDownPasswordConfirmation = (event: any) => {
        event.preventDefault();
    };

    const handleClickShowOldPassword = () => {
        setShowOldPassword(!showOldPassword);
    };

    const handleMouseDownOldPassword = (event: any) => {
        event.preventDefault();
    };

    const { errors, values, touched, handleChange, handleBlur, setValues, setTouched, handleSubmit } = useFormik({
        initialValues: {
            email: '',
            oldPassword: '',
            password: '',
            passwordConfirmation: ''
        },
        onSubmit: () => {
            setSpinning(true);
            userControllerApi
                .changePasswordRaw({
                    changePasswordRequestDTO: { oldPassword: values.oldPassword, password: values.password, email: values.email }
                })
                .then(() => {
                    onSuccess();
                    setSpinning(false);
                    handleClose();
                })
                .catch((error) => {
                    setCredentialsError(true);
                    setSpinning(false);
                });
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
            oldPassword: Yup.string().max(255, 'Password to long').required('You must provide your old password'),
            password: Yup.string().max(255, 'Password to long').required('Password is required'),
            passwordConfirmation: Yup.string()
                .max(255)
                .required('You must confirm your password')
                .oneOf([Yup.ref('password'), null], 'Passwords must match')
        })
    });

    const handleClose = () => {
        setTouched({ email: false, oldPassword: false, password: false, passwordConfirmation: false });
        setValues({ email: '', oldPassword: '', password: '', passwordConfirmation: '' });
        onClose();
    };

    const tools = [
        <Button text={'Cancel '} className={'buttonSecondary'} icon={<IconCircleX />} onClick={() => handleClose()} />,
        <SpinnerIconButton
            text={'Save'}
            textSpinning={'Saving ...'}
            className={'buttonSuccess'}
            onClick={handleSubmit}
            disabled={!_.isEmpty(errors)}
            icon={<IconCheck />}
            spinning={spinning}
        />
    ];

    return (
        <Modal
            id={'change-password-modal'}
            open={open}
            title={'Change Password'}
            width={'sm'}
            onClose={() => {
                handleClose();
            }}
            closable={true}
            buttons={tools}
        >
            <FormControl fullWidth style={{ margin: '16px 0 0 0' }}>
                <InputLabel htmlFor="outlined-adornment-email-login">Email Address</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-email-login"
                    type="email"
                    value={values.email}
                    name="email"
                    onBlur={handleBlur}
                    onChange={(event) => {
                        credentialsError && setCredentialsError(false);
                        handleChange(event);
                    }}
                    label="Email Address"
                />
                {Boolean(touched.email && !!errors.email) && (
                    <FormHelperText error id="standard-weight-helper-text-email-login">
                        {errors.email}
                    </FormHelperText>
                )}
            </FormControl>

            <FormControl fullWidth error={Boolean(touched.oldPassword && !!errors.oldPassword)} style={{ margin: '16px 0 0 0' }}>
                <InputLabel htmlFor="outlined-adornment-old-password-login">Old Password</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-old-password-login"
                    type={showOldPassword ? 'text' : 'password'}
                    value={values.oldPassword}
                    name="oldPassword"
                    autoComplete={'off'}
                    onBlur={handleBlur}
                    onChange={(event) => {
                        credentialsError && setCredentialsError(false);
                        handleChange(event);
                    }}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowOldPassword}
                                onMouseDown={handleMouseDownOldPassword}
                                edge="end"
                                size="large"
                            >
                                {showOldPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }
                    label="Old Password"
                    inputProps={{}}
                />
                {Boolean(touched.oldPassword && !!errors.oldPassword) && (
                    <FormHelperText error id="standard-weight-helper-text-old-password-login">
                        {errors.oldPassword}
                    </FormHelperText>
                )}
            </FormControl>

            <FormControl fullWidth error={Boolean(touched.password && !!errors.password)} style={{ margin: '16px 0 0 0' }}>
                <InputLabel htmlFor="outlined-adornment-password-login">Password</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-password-login"
                    type={showPassword ? 'text' : 'password'}
                    value={values.password}
                    name="password"
                    autoComplete={'off'}
                    onBlur={handleBlur}
                    onChange={(event) => {
                        credentialsError && setCredentialsError(false);
                        handleChange(event);
                    }}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                size="large"
                            >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }
                    label="Password"
                    inputProps={{}}
                />
                {Boolean(touched.password && !!errors.password) && (
                    <FormHelperText error id="standard-weight-helper-text-password-login">
                        {errors.password}
                    </FormHelperText>
                )}
            </FormControl>

            <FormControl
                fullWidth
                error={Boolean(touched.passwordConfirmation && !!errors.passwordConfirmation)}
                style={{ margin: '16px 0 0 0' }}
            >
                <InputLabel htmlFor="outlined-adornment-password-confirmation-login">Password Confirmation</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-password-confirmation-login"
                    type={showPasswordConfirmation ? 'text' : 'password'}
                    value={values.passwordConfirmation}
                    name="passwordConfirmation"
                    autoComplete={'off'}
                    onBlur={handleBlur}
                    onChange={(event) => {
                        credentialsError && setCredentialsError(false);
                        handleChange(event);
                    }}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPasswordConfirmation}
                                onMouseDown={handleMouseDownPasswordConfirmation}
                                edge="end"
                                size="large"
                            >
                                {showPasswordConfirmation ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }
                    label="Password Confirmation"
                    inputProps={{}}
                />
                {Boolean(touched.password && !!errors.passwordConfirmation) && (
                    <FormHelperText error id={'password-equals-error'}>
                        {errors.passwordConfirmation}
                    </FormHelperText>
                )}
            </FormControl>

            {credentialsError && (
                <FormHelperText error id={'credential-errors'} style={{ marginTop: 16 }}>
                    Wrong credentials. Verify email and old password provided.
                </FormHelperText>
            )}
        </Modal>
    );
};

export default ChangePassword;
