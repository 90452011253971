import { appStore } from '../../../../store/appStore';
import { RoleTypeDTO } from '../sdk';

export const isAdmin = () => {
    return appStore.user.authorities.includes(RoleTypeDTO.Admin);
};

export const isManager = () => {
    return appStore.user.authorities.includes(RoleTypeDTO.Manager);
};
