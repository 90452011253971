/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum BracketType {
    Bracket1 = 'BRACKET_1',
    Bracket2 = 'BRACKET_2',
    Bracket3 = 'BRACKET_3',
    Bracket4 = 'BRACKET_4',
    Bracket5 = 'BRACKET_5',
    Bracket6 = 'BRACKET_6',
    Flat = 'FLAT'
}

export function BracketTypeFromJSON(json: any): BracketType {
    return BracketTypeFromJSONTyped(json, false);
}

export function BracketTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): BracketType {
    return json as BracketType;
}

export function BracketTypeToJSON(value?: BracketType | null): any {
    return value as any;
}

