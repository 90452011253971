/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ServiceProvidedDTO
 */
export interface ServiceProvidedDTO {
    /**
     * 
     * @type {number}
     * @memberof ServiceProvidedDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ServiceProvidedDTO
     */
    description?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceProvidedDTO
     */
    keywords?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceProvidedDTO
     */
    units?: Array<string>;
}

export function ServiceProvidedDTOFromJSON(json: any): ServiceProvidedDTO {
    return ServiceProvidedDTOFromJSONTyped(json, false);
}

export function ServiceProvidedDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceProvidedDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'keywords': !exists(json, 'keywords') ? undefined : json['keywords'],
        'units': !exists(json, 'units') ? undefined : json['units'],
    };
}

export function ServiceProvidedDTOToJSON(value?: ServiceProvidedDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'description': value.description,
        'keywords': value.keywords,
        'units': value.units,
    };
}

