/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BracketType,
    BracketTypeFromJSON,
    BracketTypeFromJSONTyped,
    BracketTypeToJSON,
} from './BracketType';

/**
 * 
 * @export
 * @interface BracketDTO
 */
export interface BracketDTO {
    /**
     * 
     * @type {number}
     * @memberof BracketDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof BracketDTO
     */
    rateId?: number;
    /**
     * 
     * @type {string}
     * @memberof BracketDTO
     */
    identifier?: string;
    /**
     * 
     * @type {BracketType}
     * @memberof BracketDTO
     */
    type?: BracketType;
    /**
     * 
     * @type {number}
     * @memberof BracketDTO
     */
    fromValue?: number;
    /**
     * 
     * @type {number}
     * @memberof BracketDTO
     */
    toValue?: number;
    /**
     * 
     * @type {number}
     * @memberof BracketDTO
     */
    multiplier?: number;
    /**
     * 
     * @type {string}
     * @memberof BracketDTO
     */
    unit?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BracketDTO
     */
    applyTo?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BracketDTO
     */
    fireLine?: boolean;
}

export function BracketDTOFromJSON(json: any): BracketDTO {
    return BracketDTOFromJSONTyped(json, false);
}

export function BracketDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): BracketDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'rateId': !exists(json, 'rateId') ? undefined : json['rateId'],
        'identifier': !exists(json, 'identifier') ? undefined : json['identifier'],
        'type': !exists(json, 'type') ? undefined : BracketTypeFromJSON(json['type']),
        'fromValue': !exists(json, 'fromValue') ? undefined : json['fromValue'],
        'toValue': !exists(json, 'toValue') ? undefined : json['toValue'],
        'multiplier': !exists(json, 'multiplier') ? undefined : json['multiplier'],
        'unit': !exists(json, 'unit') ? undefined : json['unit'],
        'applyTo': !exists(json, 'applyTo') ? undefined : json['applyTo'],
        'fireLine': !exists(json, 'fireLine') ? undefined : json['fireLine'],
    };
}

export function BracketDTOToJSON(value?: BracketDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'rateId': value.rateId,
        'identifier': value.identifier,
        'type': BracketTypeToJSON(value.type),
        'fromValue': value.fromValue,
        'toValue': value.toValue,
        'multiplier': value.multiplier,
        'unit': value.unit,
        'applyTo': value.applyTo,
        'fireLine': value.fireLine,
    };
}

