import {
    AccountControllerApi,
    AuthenticationControllerApi,
    BillingControllerApi,
    ClientControllerApi,
    Configuration,
    ConfigurationParameters,
    InputControllerApi,
    InputTypeControllerApi,
    ManagerControllerApi,
    MeterControllerApi,
    MeterLogControllerApi,
    ProviderControllerApi,
    RateControllerApi,
    RequestContext,
    ResponseContext,
    ServiceProvidedControllerApi,
    SignatureControllerApi,
    UserControllerApi
} from './sdk';
import { appStore, EMPTY_USER } from '../../../store/appStore';

const cleanTokenAndRedirect = (message: string) => {
    localStorage.setItem('outboxtek.logintoken', JSON.stringify({}));
    appStore.user = EMPTY_USER;
    window.location.replace('/pages/login');
};

export const isDevMode = () => {
    return !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
};

export const defaultApiAuthConfig: ConfigurationParameters = {
    basePath: isDevMode() ? 'http://localhost:8080' : ''
};

export const defaultApiConfig: ConfigurationParameters = {
    basePath: isDevMode() ? 'http://localhost:8080' : '',
    middleware: [
        {
            pre(context: RequestContext): any {
                const accessToken = localStorage.getItem('outboxtek.logintoken');
                if (accessToken) {
                    let header = {
                        'Content-Type': 'application/JSON',
                        Authorization: 'Bearer ' + JSON.parse(accessToken).jwt
                    };

                    context.init.headers = header;
                }
            },
            post: async (context: ResponseContext): Promise<void> => {
                if (context.response.status === 401) {
                    cleanTokenAndRedirect('401 Permission denied, redirecting to login page');
                }

                if (context.response.status === 403) {
                    cleanTokenAndRedirect('403 Permission denied, redirecting to login page');
                }
            }
        }
    ]
};

export const apiAuthConfig = new Configuration(defaultApiAuthConfig);
export const apiConfig = new Configuration(defaultApiConfig);

export const authenticationControllerApi = new AuthenticationControllerApi(apiAuthConfig);
export const managerControllerApi: ManagerControllerApi = new ManagerControllerApi(apiConfig);
export const clientControllerApi: ClientControllerApi = new ClientControllerApi(apiConfig);
export const meterControllerApi: MeterControllerApi = new MeterControllerApi(apiConfig);
export const inputTypeControllerApi: InputTypeControllerApi = new InputTypeControllerApi(apiConfig);
export const inputControllerApi: InputControllerApi = new InputControllerApi(apiConfig);
export const serviceProvidedControllerApi: ServiceProvidedControllerApi = new ServiceProvidedControllerApi(apiConfig);
export const rateControllerApi: RateControllerApi = new RateControllerApi(apiConfig);
export const providerControllerApi: ProviderControllerApi = new ProviderControllerApi(apiConfig);
export const accountControllerApi: AccountControllerApi = new AccountControllerApi(apiConfig);
export const userControllerApi: UserControllerApi = new UserControllerApi(apiConfig);
export const billingControllerApi: BillingControllerApi = new BillingControllerApi(apiConfig);
export const signatureControllerApi: SignatureControllerApi = new SignatureControllerApi(apiConfig);
export const meterLogControllerApi: MeterLogControllerApi = new MeterLogControllerApi(apiConfig);
