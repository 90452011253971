/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BillDetailsDTO,
    BillDetailsDTOFromJSON,
    BillDetailsDTOToJSON,
} from '../models';

export interface CreatePdfBillRequest {
    billDetailsDTO: BillDetailsDTO;
}

export interface SendEmailBillingRequest {
    billDetailsDTO: BillDetailsDTO;
}

/**
 * 
 */
export class BillingControllerApi extends runtime.BaseAPI {

    /**
     */
    async createPdfBillRaw(requestParameters: CreatePdfBillRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.billDetailsDTO === null || requestParameters.billDetailsDTO === undefined) {
            throw new runtime.RequiredError('billDetailsDTO','Required parameter requestParameters.billDetailsDTO was null or undefined when calling createPdfBill.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/billing/generate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BillDetailsDTOToJSON(requestParameters.billDetailsDTO),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async createPdfBill(requestParameters: CreatePdfBillRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.createPdfBillRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async sendEmailBillingRaw(requestParameters: SendEmailBillingRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.billDetailsDTO === null || requestParameters.billDetailsDTO === undefined) {
            throw new runtime.RequiredError('billDetailsDTO','Required parameter requestParameters.billDetailsDTO was null or undefined when calling sendEmailBilling.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/billing/send`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BillDetailsDTOToJSON(requestParameters.billDetailsDTO),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async sendEmailBilling(requestParameters: SendEmailBillingRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.sendEmailBillingRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
