/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NewUnitRequestDTO
 */
export interface NewUnitRequestDTO {
    /**
     * 
     * @type {number}
     * @memberof NewUnitRequestDTO
     */
    serviceId?: number;
    /**
     * 
     * @type {string}
     * @memberof NewUnitRequestDTO
     */
    value?: string;
}

export function NewUnitRequestDTOFromJSON(json: any): NewUnitRequestDTO {
    return NewUnitRequestDTOFromJSONTyped(json, false);
}

export function NewUnitRequestDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewUnitRequestDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'serviceId': !exists(json, 'serviceId') ? undefined : json['serviceId'],
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function NewUnitRequestDTOToJSON(value?: NewUnitRequestDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'serviceId': value.serviceId,
        'value': value.value,
    };
}

