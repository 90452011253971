import { getUserRole } from '../auth/authService';
import { RoleTypeDTO } from '../../src/libs/src/lib/sdk/models/RoleTypeDTO';
import dashboardManager from './dashboardManager';
import dashboardAdmin from './dashboardAdmin';

// ==============================|| MENU ITEMS ||============================== //

const getMenuSections = () => {
    switch (getUserRole()) {
        case RoleTypeDTO.Manager:
            return [dashboardManager];
        case RoleTypeDTO.Admin:
            return [dashboardAdmin];
        default:
            return [];
    }
};

const menuItems = {
    items: getMenuSections()
};

export default menuItems;
