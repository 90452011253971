/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MeterDTO,
    MeterDTOFromJSON,
    MeterDTOToJSON,
    MeterListResponseDTO,
    MeterListResponseDTOFromJSON,
    MeterListResponseDTOToJSON,
} from '../models';

export interface CreateMeterRequest {
    meterDTO: MeterDTO;
}

export interface GetMeterRequest {
    id: number;
}

export interface GetMetersByAccountManagerRequest {
    accountId: number;
}

export interface GetMetersByManagerRequest {
    managerId: number;
}

export interface RemoveMeterRequest {
    id: number;
}

export interface UpdateMeterRequest {
    meterDTO: MeterDTO;
}

/**
 * 
 */
export class MeterControllerApi extends runtime.BaseAPI {

    /**
     */
    async createMeterRaw(requestParameters: CreateMeterRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MeterDTO>> {
        if (requestParameters.meterDTO === null || requestParameters.meterDTO === undefined) {
            throw new runtime.RequiredError('meterDTO','Required parameter requestParameters.meterDTO was null or undefined when calling createMeter.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/meters`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MeterDTOToJSON(requestParameters.meterDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeterDTOFromJSON(jsonValue));
    }

    /**
     */
    async createMeter(requestParameters: CreateMeterRequest, initOverrides?: RequestInit): Promise<MeterDTO> {
        const response = await this.createMeterRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getMeterRaw(requestParameters: GetMeterRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MeterDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getMeter.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/meters/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeterDTOFromJSON(jsonValue));
    }

    /**
     */
    async getMeter(requestParameters: GetMeterRequest, initOverrides?: RequestInit): Promise<MeterDTO> {
        const response = await this.getMeterRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getMetersByAccountManagerRaw(requestParameters: GetMetersByAccountManagerRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MeterListResponseDTO>> {
        if (requestParameters.accountId === null || requestParameters.accountId === undefined) {
            throw new runtime.RequiredError('accountId','Required parameter requestParameters.accountId was null or undefined when calling getMetersByAccountManager.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/meters/get-by-account-manager/{accountId}`.replace(`{${"accountId"}}`, encodeURIComponent(String(requestParameters.accountId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeterListResponseDTOFromJSON(jsonValue));
    }

    /**
     */
    async getMetersByAccountManager(requestParameters: GetMetersByAccountManagerRequest, initOverrides?: RequestInit): Promise<MeterListResponseDTO> {
        const response = await this.getMetersByAccountManagerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getMetersByManagerRaw(requestParameters: GetMetersByManagerRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MeterListResponseDTO>> {
        if (requestParameters.managerId === null || requestParameters.managerId === undefined) {
            throw new runtime.RequiredError('managerId','Required parameter requestParameters.managerId was null or undefined when calling getMetersByManager.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/meters/manager/{managerId}`.replace(`{${"managerId"}}`, encodeURIComponent(String(requestParameters.managerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeterListResponseDTOFromJSON(jsonValue));
    }

    /**
     */
    async getMetersByManager(requestParameters: GetMetersByManagerRequest, initOverrides?: RequestInit): Promise<MeterListResponseDTO> {
        const response = await this.getMetersByManagerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listMetersRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<MeterDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/meters`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MeterDTOFromJSON));
    }

    /**
     */
    async listMeters(initOverrides?: RequestInit): Promise<Array<MeterDTO>> {
        const response = await this.listMetersRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async removeMeterRaw(requestParameters: RemoveMeterRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling removeMeter.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/meters/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async removeMeter(requestParameters: RemoveMeterRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.removeMeterRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateMeterRaw(requestParameters: UpdateMeterRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MeterDTO>> {
        if (requestParameters.meterDTO === null || requestParameters.meterDTO === undefined) {
            throw new runtime.RequiredError('meterDTO','Required parameter requestParameters.meterDTO was null or undefined when calling updateMeter.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/meters`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MeterDTOToJSON(requestParameters.meterDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeterDTOFromJSON(jsonValue));
    }

    /**
     */
    async updateMeter(requestParameters: UpdateMeterRequest, initOverrides?: RequestInit): Promise<MeterDTO> {
        const response = await this.updateMeterRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
