/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MeterDTO,
    MeterDTOFromJSON,
    MeterDTOFromJSONTyped,
    MeterDTOToJSON,
} from './MeterDTO';

/**
 * 
 * @export
 * @interface MeterListResponseDTO
 */
export interface MeterListResponseDTO {
    /**
     * 
     * @type {Array<MeterDTO>}
     * @memberof MeterListResponseDTO
     */
    meters?: Array<MeterDTO>;
}

export function MeterListResponseDTOFromJSON(json: any): MeterListResponseDTO {
    return MeterListResponseDTOFromJSONTyped(json, false);
}

export function MeterListResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeterListResponseDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'meters': !exists(json, 'meters') ? undefined : ((json['meters'] as Array<any>).map(MeterDTOFromJSON)),
    };
}

export function MeterListResponseDTOToJSON(value?: MeterListResponseDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'meters': value.meters === undefined ? undefined : ((value.meters as Array<any>).map(MeterDTOToJSON)),
    };
}

