/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ChangePasswordRequestDTO,
    ChangePasswordRequestDTOFromJSON,
    ChangePasswordRequestDTOToJSON,
} from '../models';

export interface ChangePasswordRequest {
    changePasswordRequestDTO: ChangePasswordRequestDTO;
}

/**
 * 
 */
export class UserControllerApi extends runtime.BaseAPI {

    /**
     */
    async changePasswordRaw(requestParameters: ChangePasswordRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.changePasswordRequestDTO === null || requestParameters.changePasswordRequestDTO === undefined) {
            throw new runtime.RequiredError('changePasswordRequestDTO','Required parameter requestParameters.changePasswordRequestDTO was null or undefined when calling changePassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/users/change-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangePasswordRequestDTOToJSON(requestParameters.changePasswordRequestDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async changePassword(requestParameters: ChangePasswordRequest, initOverrides?: RequestInit): Promise<void> {
        await this.changePasswordRaw(requestParameters, initOverrides);
    }

}
