/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RoleDTO,
    RoleDTOFromJSON,
    RoleDTOFromJSONTyped,
    RoleDTOToJSON,
} from './RoleDTO';

/**
 * 
 * @export
 * @interface ManagerDTO
 */
export interface ManagerDTO {
    /**
     * 
     * @type {number}
     * @memberof ManagerDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ManagerDTO
     */
    email?: string;
    /**
     * 
     * @type {Set<RoleDTO>}
     * @memberof ManagerDTO
     */
    roles?: Set<RoleDTO>;
    /**
     * 
     * @type {string}
     * @memberof ManagerDTO
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagerDTO
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagerDTO
     */
    additionalInfo?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ManagerDTO
     */
    emailSended?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ManagerDTO
     */
    accountsIdentifier?: Array<string>;
}

export function ManagerDTOFromJSON(json: any): ManagerDTO {
    return ManagerDTOFromJSONTyped(json, false);
}

export function ManagerDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManagerDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'roles': !exists(json, 'roles') ? undefined : (new Set((json['roles'] as Array<any>).map(RoleDTOFromJSON))),
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'additionalInfo': !exists(json, 'additionalInfo') ? undefined : json['additionalInfo'],
        'emailSended': !exists(json, 'emailSended') ? undefined : json['emailSended'],
        'accountsIdentifier': !exists(json, 'accountsIdentifier') ? undefined : json['accountsIdentifier'],
    };
}

export function ManagerDTOToJSON(value?: ManagerDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'email': value.email,
        'roles': value.roles === undefined ? undefined : (Array.from(value.roles as Set<any>).map(RoleDTOToJSON)),
        'firstName': value.firstName,
        'lastName': value.lastName,
        'additionalInfo': value.additionalInfo,
        'emailSended': value.emailSended,
        'accountsIdentifier': value.accountsIdentifier,
    };
}

