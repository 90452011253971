/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    RateDTO,
    RateDTOFromJSON,
    RateDTOToJSON,
    RateInfoRequestDTO,
    RateInfoRequestDTOFromJSON,
    RateInfoRequestDTOToJSON,
} from '../models';

export interface CreateRateRequest {
    rateDTO: RateDTO;
}

export interface FindRateRequest {
    state: string;
    city: string;
    provider: number;
    service: number;
}

export interface GetRateRequest {
    id: number;
}

export interface GetRatesRequest {
    rateInfoRequestDTO: RateInfoRequestDTO;
}

export interface RemoveRateRequest {
    id: number;
}

export interface UpdateRateRequest {
    rateDTO: RateDTO;
}

/**
 * 
 */
export class RateControllerApi extends runtime.BaseAPI {

    /**
     */
    async createRateRaw(requestParameters: CreateRateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RateDTO>> {
        if (requestParameters.rateDTO === null || requestParameters.rateDTO === undefined) {
            throw new runtime.RequiredError('rateDTO','Required parameter requestParameters.rateDTO was null or undefined when calling createRate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/rates`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RateDTOToJSON(requestParameters.rateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RateDTOFromJSON(jsonValue));
    }

    /**
     */
    async createRate(requestParameters: CreateRateRequest, initOverrides?: RequestInit): Promise<RateDTO> {
        const response = await this.createRateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findRateRaw(requestParameters: FindRateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<RateDTO>>> {
        if (requestParameters.state === null || requestParameters.state === undefined) {
            throw new runtime.RequiredError('state','Required parameter requestParameters.state was null or undefined when calling findRate.');
        }

        if (requestParameters.city === null || requestParameters.city === undefined) {
            throw new runtime.RequiredError('city','Required parameter requestParameters.city was null or undefined when calling findRate.');
        }

        if (requestParameters.provider === null || requestParameters.provider === undefined) {
            throw new runtime.RequiredError('provider','Required parameter requestParameters.provider was null or undefined when calling findRate.');
        }

        if (requestParameters.service === null || requestParameters.service === undefined) {
            throw new runtime.RequiredError('service','Required parameter requestParameters.service was null or undefined when calling findRate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/rates/state/{state}/city/{city}/provider/{provider}/service/{service}`.replace(`{${"state"}}`, encodeURIComponent(String(requestParameters.state))).replace(`{${"city"}}`, encodeURIComponent(String(requestParameters.city))).replace(`{${"provider"}}`, encodeURIComponent(String(requestParameters.provider))).replace(`{${"service"}}`, encodeURIComponent(String(requestParameters.service))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RateDTOFromJSON));
    }

    /**
     */
    async findRate(requestParameters: FindRateRequest, initOverrides?: RequestInit): Promise<Array<RateDTO>> {
        const response = await this.findRateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getRateRaw(requestParameters: GetRateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RateDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getRate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/rates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RateDTOFromJSON(jsonValue));
    }

    /**
     */
    async getRate(requestParameters: GetRateRequest, initOverrides?: RequestInit): Promise<RateDTO> {
        const response = await this.getRateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getRatesRaw(requestParameters: GetRatesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<RateDTO>>> {
        if (requestParameters.rateInfoRequestDTO === null || requestParameters.rateInfoRequestDTO === undefined) {
            throw new runtime.RequiredError('rateInfoRequestDTO','Required parameter requestParameters.rateInfoRequestDTO was null or undefined when calling getRates.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/rates/info`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RateInfoRequestDTOToJSON(requestParameters.rateInfoRequestDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RateDTOFromJSON));
    }

    /**
     */
    async getRates(requestParameters: GetRatesRequest, initOverrides?: RequestInit): Promise<Array<RateDTO>> {
        const response = await this.getRatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listRatesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<RateDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/rates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RateDTOFromJSON));
    }

    /**
     */
    async listRates(initOverrides?: RequestInit): Promise<Array<RateDTO>> {
        const response = await this.listRatesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async removeRateRaw(requestParameters: RemoveRateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling removeRate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/rates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async removeRate(requestParameters: RemoveRateRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.removeRateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateRateRaw(requestParameters: UpdateRateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RateDTO>> {
        if (requestParameters.rateDTO === null || requestParameters.rateDTO === undefined) {
            throw new runtime.RequiredError('rateDTO','Required parameter requestParameters.rateDTO was null or undefined when calling updateRate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/rates`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RateDTOToJSON(requestParameters.rateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RateDTOFromJSON(jsonValue));
    }

    /**
     */
    async updateRate(requestParameters: UpdateRateRequest, initOverrides?: RequestInit): Promise<RateDTO> {
        const response = await this.updateRateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
