/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClientDTO,
    ClientDTOFromJSON,
    ClientDTOFromJSONTyped,
    ClientDTOToJSON,
} from './ClientDTO';

/**
 * 
 * @export
 * @interface AccountDTO
 */
export interface AccountDTO {
    /**
     * 
     * @type {number}
     * @memberof AccountDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountDTO
     */
    identifier?: string;
    /**
     * 
     * @type {number}
     * @memberof AccountDTO
     */
    managerId?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountDTO
     */
    managerFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDTO
     */
    managerLastName?: string;
    /**
     * 
     * @type {number}
     * @memberof AccountDTO
     */
    totalMeters?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountDTO
     */
    maxMeters?: number;
    /**
     * 
     * @type {Array<ClientDTO>}
     * @memberof AccountDTO
     */
    clients?: Array<ClientDTO>;
    /**
     * 
     * @type {string}
     * @memberof AccountDTO
     */
    pushKey?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountDTO
     */
    maxMeterReached?: boolean;
}

export function AccountDTOFromJSON(json: any): AccountDTO {
    return AccountDTOFromJSONTyped(json, false);
}

export function AccountDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'identifier': !exists(json, 'identifier') ? undefined : json['identifier'],
        'managerId': !exists(json, 'managerId') ? undefined : json['managerId'],
        'managerFirstName': !exists(json, 'managerFirstName') ? undefined : json['managerFirstName'],
        'managerLastName': !exists(json, 'managerLastName') ? undefined : json['managerLastName'],
        'totalMeters': !exists(json, 'totalMeters') ? undefined : json['totalMeters'],
        'maxMeters': !exists(json, 'maxMeters') ? undefined : json['maxMeters'],
        'clients': !exists(json, 'clients') ? undefined : ((json['clients'] as Array<any>).map(ClientDTOFromJSON)),
        'pushKey': !exists(json, 'pushKey') ? undefined : json['pushKey'],
        'maxMeterReached': !exists(json, 'maxMeterReached') ? undefined : json['maxMeterReached'],
    };
}

export function AccountDTOToJSON(value?: AccountDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'identifier': value.identifier,
        'managerId': value.managerId,
        'managerFirstName': value.managerFirstName,
        'managerLastName': value.managerLastName,
        'totalMeters': value.totalMeters,
        'maxMeters': value.maxMeters,
        'clients': value.clients === undefined ? undefined : ((value.clients as Array<any>).map(ClientDTOToJSON)),
        'pushKey': value.pushKey,
        'maxMeterReached': value.maxMeterReached,
    };
}

