// assets
import { IconDashboard, IconDiscount, IconUsers, IconBuildingFactory, IconBusinessplan, IconRouter, IconApps } from '@tabler/icons';

// constant
const icons = { IconDashboard, IconDiscount, IconUsers, IconBuildingFactory, IconBusinessplan, IconRouter, IconApps };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboardAdmin = {
    id: 'dashboard',
    title: 'Menu',
    type: 'group',
    children: [
        {
            id: 'managers',
            title: 'Managers',
            type: 'item',
            url: '/managers',
            icon: icons.IconUsers,
            breadcrumbs: false
        },
        {
            id: 'accounts',
            title: 'Accounts',
            type: 'item',
            url: '/accounts',
            icon: icons.IconApps,
            breadcrumbs: false
        },
        {
            id: 'meters',
            title: 'Meters',
            type: 'item',
            url: '/meters',
            icon: icons.IconRouter,
            breadcrumbs: false
        },
        {
            id: 'providers',
            title: 'Service Providers',
            type: 'item',
            url: '/providers',
            icon: icons.IconBuildingFactory,
            breadcrumbs: false
        },
        {
            id: 'rates',
            title: 'Rates',
            type: 'item',
            url: '/rates',
            icon: icons.IconBusinessplan,
            breadcrumbs: false
        }
    ]
};

export default dashboardAdmin;
