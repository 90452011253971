/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AccountDTO,
    AccountDTOFromJSON,
    AccountDTOToJSON,
    MeterDTO,
    MeterDTOFromJSON,
    MeterDTOToJSON,
} from '../models';

export interface CreateAccountRequest {
    accountDTO: AccountDTO;
}

export interface GetAccountRequest {
    id: number;
}

export interface GetAccountMetersRequest {
    id: number;
}

export interface GetMaxMetersReachedRequest {
    id: number;
}

export interface ListAccountsByManagerIdRequest {
    managerId: number;
}

export interface RemoveAccountRequest {
    id: number;
}

export interface UpdateAccountRequest {
    accountDTO: AccountDTO;
}

/**
 * 
 */
export class AccountControllerApi extends runtime.BaseAPI {

    /**
     */
    async createAccountRaw(requestParameters: CreateAccountRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AccountDTO>> {
        if (requestParameters.accountDTO === null || requestParameters.accountDTO === undefined) {
            throw new runtime.RequiredError('accountDTO','Required parameter requestParameters.accountDTO was null or undefined when calling createAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/accounts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AccountDTOToJSON(requestParameters.accountDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountDTOFromJSON(jsonValue));
    }

    /**
     */
    async createAccount(requestParameters: CreateAccountRequest, initOverrides?: RequestInit): Promise<AccountDTO> {
        const response = await this.createAccountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAccountRaw(requestParameters: GetAccountRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AccountDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/accounts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountDTOFromJSON(jsonValue));
    }

    /**
     */
    async getAccount(requestParameters: GetAccountRequest, initOverrides?: RequestInit): Promise<AccountDTO> {
        const response = await this.getAccountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAccountMetersRaw(requestParameters: GetAccountMetersRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<MeterDTO>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getAccountMeters.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/accounts/{id}/meters`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MeterDTOFromJSON));
    }

    /**
     */
    async getAccountMeters(requestParameters: GetAccountMetersRequest, initOverrides?: RequestInit): Promise<Array<MeterDTO>> {
        const response = await this.getAccountMetersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getMaxMetersReachedRaw(requestParameters: GetMaxMetersReachedRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getMaxMetersReached.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/accounts/{id}/max-meters-reached`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async getMaxMetersReached(requestParameters: GetMaxMetersReachedRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.getMaxMetersReachedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listAccountsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<AccountDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/accounts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AccountDTOFromJSON));
    }

    /**
     */
    async listAccounts(initOverrides?: RequestInit): Promise<Array<AccountDTO>> {
        const response = await this.listAccountsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async listAccountsByManagerIdRaw(requestParameters: ListAccountsByManagerIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<AccountDTO>>> {
        if (requestParameters.managerId === null || requestParameters.managerId === undefined) {
            throw new runtime.RequiredError('managerId','Required parameter requestParameters.managerId was null or undefined when calling listAccountsByManagerId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/accounts/manager/{managerId}`.replace(`{${"managerId"}}`, encodeURIComponent(String(requestParameters.managerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AccountDTOFromJSON));
    }

    /**
     */
    async listAccountsByManagerId(requestParameters: ListAccountsByManagerIdRequest, initOverrides?: RequestInit): Promise<Array<AccountDTO>> {
        const response = await this.listAccountsByManagerIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async removeAccountRaw(requestParameters: RemoveAccountRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling removeAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/accounts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async removeAccount(requestParameters: RemoveAccountRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.removeAccountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateAccountRaw(requestParameters: UpdateAccountRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AccountDTO>> {
        if (requestParameters.accountDTO === null || requestParameters.accountDTO === undefined) {
            throw new runtime.RequiredError('accountDTO','Required parameter requestParameters.accountDTO was null or undefined when calling updateAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/accounts`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AccountDTOToJSON(requestParameters.accountDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountDTOFromJSON(jsonValue));
    }

    /**
     */
    async updateAccount(requestParameters: UpdateAccountRequest, initOverrides?: RequestInit): Promise<AccountDTO> {
        const response = await this.updateAccountRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
