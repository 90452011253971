// assets
import { IconListDetails, IconUsers, IconRouter, IconSitemap } from '@tabler/icons';

// constant
const icons = { IconListDetails, IconUsers, IconRouter, IconSitemap };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboardManager = {
    id: 'dashboard',
    title: 'Menu',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: 'Accounts',
            type: 'item',
            url: '/dashboard',
            icon: icons.IconListDetails,
            breadcrumbs: false
        },
        {
            id: 'clients',
            title: 'Clients',
            type: 'item',
            url: '/clients',
            icon: icons.IconUsers,
            breadcrumbs: false
        },
        {
            id: 'meters',
            title: 'Meters',
            type: 'item',
            url: '/meters',
            icon: icons.IconRouter,
            breadcrumbs: false
        },
        {
            id: 'billing',
            title: 'Billing',
            type: 'item',
            url: '/billing',
            icon: icons.IconSitemap,
            breadcrumbs: false
        }
    ]
};

export default dashboardManager;
