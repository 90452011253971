/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InputDTO,
    InputDTOFromJSON,
    InputDTOToJSON,
} from '../models';

export interface CreateInputRequest {
    inputDTO: InputDTO;
}

export interface GetInputRequest {
    id: number;
}

export interface RemoveInputRequest {
    id: number;
}

export interface UpdateInputRequest {
    inputDTO: InputDTO;
}

/**
 * 
 */
export class InputControllerApi extends runtime.BaseAPI {

    /**
     */
    async createInputRaw(requestParameters: CreateInputRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InputDTO>> {
        if (requestParameters.inputDTO === null || requestParameters.inputDTO === undefined) {
            throw new runtime.RequiredError('inputDTO','Required parameter requestParameters.inputDTO was null or undefined when calling createInput.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/inputs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InputDTOToJSON(requestParameters.inputDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InputDTOFromJSON(jsonValue));
    }

    /**
     */
    async createInput(requestParameters: CreateInputRequest, initOverrides?: RequestInit): Promise<InputDTO> {
        const response = await this.createInputRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getInputRaw(requestParameters: GetInputRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InputDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getInput.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/inputs/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InputDTOFromJSON(jsonValue));
    }

    /**
     */
    async getInput(requestParameters: GetInputRequest, initOverrides?: RequestInit): Promise<InputDTO> {
        const response = await this.getInputRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listInputsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<InputDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/inputs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InputDTOFromJSON));
    }

    /**
     */
    async listInputs(initOverrides?: RequestInit): Promise<Array<InputDTO>> {
        const response = await this.listInputsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async removeInputRaw(requestParameters: RemoveInputRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling removeInput.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/inputs/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async removeInput(requestParameters: RemoveInputRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.removeInputRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateInputRaw(requestParameters: UpdateInputRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InputDTO>> {
        if (requestParameters.inputDTO === null || requestParameters.inputDTO === undefined) {
            throw new runtime.RequiredError('inputDTO','Required parameter requestParameters.inputDTO was null or undefined when calling updateInput.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/inputs`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InputDTOToJSON(requestParameters.inputDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InputDTOFromJSON(jsonValue));
    }

    /**
     */
    async updateInput(requestParameters: UpdateInputRequest, initOverrides?: RequestInit): Promise<InputDTO> {
        const response = await this.updateInputRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
