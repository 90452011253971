/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Tier
 */
export interface Tier {
    /**
     * 
     * @type {string}
     * @memberof Tier
     */
    identifier?: string;
    /**
     * 
     * @type {string}
     * @memberof Tier
     */
    usage?: string;
    /**
     * 
     * @type {string}
     * @memberof Tier
     */
    multiplier?: string;
    /**
     * 
     * @type {string}
     * @memberof Tier
     */
    unit?: string;
    /**
     * 
     * @type {string}
     * @memberof Tier
     */
    cost?: string;
}

export function TierFromJSON(json: any): Tier {
    return TierFromJSONTyped(json, false);
}

export function TierFromJSONTyped(json: any, ignoreDiscriminator: boolean): Tier {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'identifier': !exists(json, 'identifier') ? undefined : json['identifier'],
        'usage': !exists(json, 'usage') ? undefined : json['usage'],
        'multiplier': !exists(json, 'multiplier') ? undefined : json['multiplier'],
        'unit': !exists(json, 'unit') ? undefined : json['unit'],
        'cost': !exists(json, 'cost') ? undefined : json['cost'],
    };
}

export function TierToJSON(value?: Tier | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'identifier': value.identifier,
        'usage': value.usage,
        'multiplier': value.multiplier,
        'unit': value.unit,
        'cost': value.cost,
    };
}

