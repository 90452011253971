import { useRoutes } from 'react-router-dom';

// routes
import config from 'config';
import AdminRoutesDescription from './AdminRoutesDescription';

// ==============================|| ROUTING RENDER ||============================== //

export default function AdminRoutes() {
    return useRoutes([AdminRoutesDescription], config.basename);
}
